const deDe = {
    global: {
        appName: "Niet Handels Goederen",
        collection: "Sammlung",
        continueShopping: "Einkaufen fortfahren",
        searchTermResults: (searchterm) => `Ergebnisse Suche "${searchterm}"`,
        articlesAmount: (amount) => `${amount} Artikel(s)`,
        color: "Farbe",
        colors: "Farben",
        size: "Größe",
        sizes: "Größen",
        brand: "Marke",
        brands: "Marken",
        search: "Suchen",
        price: "Preis",
        noFiltersFound: (filterName) => `Kein ${filterName} gefunden.`,
        moreFilters: (filterName) => `Mehr ${filterName}`,
        lessFilters: (filterName) => `Weniger ${filterName}`,
        inStock: "Auf Lager",
        selectedFilters: "Ausgewählte(r) Filter",
        deleteAllFilters: "Alles löschen",
        sortOptionName: "Name",
        sortOptionPriceAsc: "Preis steigend",
        sortOptionPriceDesc: "Preis sinkend",
        amount: "Anzahl",
        noProductFound: "Kein Produkt gefunden",
        backToOverview: "Zurück zur Übersicht",
        back: "Zurück",
        gallery: "Galerie",
        stockInformation: "Lagerinformationen",
        noStockInfoFound: "Es wurden keine Bestandsinformationen gefunden.",
        noSizesFound: "Es wurden keine Größen gefunden.",
        noStoresFound: "Keine Geschäfte gefunden",
        noStockFound: "Es wurde kein Bestand gefunden.",
        maxToOrder: "Max. auf Bestellung",
        moreBranches: "Mehr Filialen",
        lessBranches: "Weniger Filialen",
        addToCart: "In den Warenkorb legen",
        viewStockInformation: "Lagerinformationen anzeigen",
        thankYou: "Danke",
        id: "ID",
        date: "Datum",
        productNotInCatalog: "Produkt ist außerhalb des Sortiments",
        information: "Information",
        productInformation: "Produktinformation",
        articles: "Artikel",
        myOrders: "Meine Bestellungen",
        shoppingCart: "Einkaufswagen",
        orderOverview: "Bestellübersicht",
        orderNumber: "Bestellnummer",
        continueOrdering: "Weiter zur Bestellung",
        total: "Gesamt",
        delete: "Entfernen",
        firstName: "Vorname",
        lastName: "Nachname",
        emailAddress: "E-Mail Addresse",
        phonenumber: "Telefonnummer",
        placeOrder: "Eine Bestellung aufgeben",
        orderReceived: "Ihre Bestellung ist in gutem Zustand eingegangen.",
        proceedShopping: "Sie können Ihren Einkauf sofort fortsetzen, indem Sie auf die Schaltfläche unten klicken.",
        previous: "Früher",
        action: "Aktion",
        view: "Aussicht",
        branch: "Zweig"
    },
    checkout: {
        shoppingCart: "Einkaufswagen"
    },
    navigation: {
        collection: "Sammlung",
        orders: "Aufträge"
    },
    messages: {
        error: {
            orderError: "Bei Ihrer Bestellung ist etwas schief gelaufen.",
            addToCartError: "Etwas ist schief gelaufen, das Produkt konnte nicht in den Warenkorb gelegt werden.",
            ordersFetch: "Something went wrong retrieving the orders."
        },
        warning: {
            discountInvalid: "Rabatt entspricht oder übersteigt den Gesamtbetrag aller Produkte, passen Sie den Rabatt an.",
            maxAmountItems: (amount) => `Max. ${amount} Stück`,
            noStock: (name) => `Das Produkt ${name} ist nicht auf Lager, entfernen Sie dieses Produkt aus Ihrem Einkaufswagen.`,
            oneInStock: (name) =>
                `Es ist noch 1 Stück des Produkts ${name} auf Lager, bitte ändern Sie die Menge dieses Produkts, wenn Sie fortfahren möchten.`,
            lessInStock: (stockAmount, name, chosenAmount) =>
                `Es sind ${stockAmount} des Produkts ${name} auf Lager, Sie haben ${chosenAmount} gewählt, ändern Sie die Menge dieses Produkts, wenn Sie fortfahren möchten.`,
            notEnoughStock: (name, stock) =>
                `Es ist nicht genug auf Lager des Produkts ${name} - ${stock} auf Lager - passen Sie die Menge dieses Produkts an und versuchen Sie es erneut.`,
            noStockForSize: (name, size) =>
                `Für die Größe ${size} des Produkts ist kein Lagerbestand vorhanden. ${name} dieses Produkt aus Ihrem Einkaufswagen entfernen.`,
            notEnoughStockForSize: (name, size, stock) =>
                `Es ist nicht genug Lagerbestand für die Größe ${size} des Produkts ${name} (noch ${stock} auf Lager), passen Sie die Menge dieses Produkts an und versuchen Sie es erneut .`,
            noProductsInCartInStock: "Keines der Produkte in Ihrem Warenkorb ist auf Lager.",
            orderWasNotPlaced: "Bestellung wurde nicht aufgegeben.",
            sizeColorComboNotFound: "Die ausgewählte Farb-/Größenkombination konnte nicht gefunden werden.",
            noBranchMatch: "Achtung: Sie sind mit keiner Einrichtung verbunden, daher können Sie keine Bestellungen aufgeben.",
            branchFetchFailedNoMatch:
                "Achtung: Beim Abrufen der Standorte ist ein Fehler aufgetreten. Daher wurde kein Link zu einem Standort gefunden und Sie können keine Bestellungen aufgeben."
        },
        info: {
            noProductsFoundWithFilters: "Mit den aktuellen Filtern wurden keine Produkte gefunden.",
            noProductsInCart: "Sie haben keine Produkte in Ihrem Warenkorb.",
            amountUpdated: (name, changedAmount) => `Der Betrag für ${name} wurde in ${changedAmount} geändert.`,
            productWithColorAndSizeRemoved: (name, color, size) =>
                `Das Produkt ${name} mit der Farbe ${color} in der Größe ${size} wurde aus Ihrem Einkaufswagen entfernt.`,
            productWithSizeRemoved: (name, size) => `Das Produkt ${name} in der Größe ${size} wurde aus Ihrem Einkaufswagen entfernt.`,
            noOrderHistory: "Keine Bestellhistorie gefunden...",
            dataWillBeRefreshed: "Die Daten der Anwendung sind veraltet und werden automatisch aktualisiert, bitte warten.",
            xOrdersFound: (count) => `${count} Bestellung(en) gefunden`,
            orderIsBeingPlaced: "Ihre Bestellung wird aufgegeben."
        },
        success: {
            orderWasPlaced: "Deine Bestellung wurde aufgenommen.",
            hasBeenAddedToThe: "wurde hinzugefügt zu"
        },
        validation: {
            noBranchOrderDisallowed: "Sie können keine Bestellung aufgeben, da Sie mit keiner Einrichtung verbunden sind.",
            noBranchOrderViewDisallowed: "Sie sind keinem Betrieb zugeordnet,<br/>d.h. Sie können <b>keine</b> Bestellungen einsehen.",
            selectAColorPlease: "Bitte wähle eine Farbe aus.",
            selectASizePlease: "Bitte Größe wählen.",
            selectAnAmountPlease: "Bitte wählen Sie einige aus.",
            notEnoughInStock: "Es ist nicht genug auf Lager, passen Sie die Mengen an.",
            notEnoughStock: "Es ist nicht genug auf Lager"
        },
        loading: {
            default: "Data wird geladen...",
            branches: "Lade Orte...",
            products: "Produkte werden geladen...",
            properties: "Eigenschaften werden geladen...",
            propertyTypes: "Eigenschaftstypen werden geladen...",
            categories: "Kategorien werden geladen...",
            inventory: "Bestände werden geladen...",
            prices: "Preise werden geladen...",
            productProperties: "Produkteigenschaften werden geladen...",
            stock: "Lager wird geladen...",
            settings: "Lade Einstellungen...",
            catalog: "Katalog wird geladen..."
        }
    }
};

export default deDe;
