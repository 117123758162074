const nlNl = {
    global: {
        appName: "Niet Handels Goederen",
        collection: "Collectie",
        continueShopping: "Verder winkelen",
        searchTermResults: (searchterm) => `Resultaten zoekopdracht "${searchterm}"`,
        articlesAmount: (amount) => `${amount} artikel(en)`,
        color: "Kleur",
        colors: "Kleuren",
        size: "Maat",
        sizes: "Maten",
        brand: "Merk",
        brands: "Merken",
        search: "Zoeken",
        price: "Prijs",
        noFiltersFound: (filterName) => `Geen ${filterName} gevonden.`,
        moreFilters: (filterName) => `Meer ${filterName}`,
        lessFilters: (filterName) => `Minder ${filterName}`,
        inStock: "Op voorraad",
        selectedFilters: "Geselecteerde filter(s)",
        deleteAllFilters: "Verwijder alles",
        sortOptionName: "Naam",
        sortOptionPriceAsc: "Prijs oplopend",
        sortOptionPriceDesc: "Prijs aflopend",
        amount: "Aantal",
        noProductFound: "Product niet gevonden",
        backToOverview: "Terug naar collectie",
        back: "Terug",
        gallery: "Gallerij",
        stockInformation: "Voorraadinformatie",
        noStockInfoFound: "Er is geen voorraad informatie gevonden.",
        noSizesFound: "Er zijn geen maten gevonden.",
        noStoresFound: "Er zijn geen winkels gevonden",
        noStockFound: "Er is geen voorraad gevonden.",
        maxToOrder: "Max. te bestellen",
        moreBranches: "Meer vestigingen",
        lessBranches: "Minder vestigingen",
        addToCart: "Voeg toe aan winkelwagen",
        viewStockInformation: "Voorraadinformatie bekijken",
        thankYou: "Bedankt",
        id: "ID",
        date: "Datum",
        productNotInCatalog: "Product is uit het assortiment",
        information: "Informatie",
        productInformation: "Productinformatie",
        articles: "Artikelen",
        myOrders: "Mijn orders",
        shoppingCart: "winkelwagen",
        orderOverview: "Besteloverzicht",
        orderNumber: "Ordernummer",
        continueOrdering: "Doorgaan met bestellen",
        total: "Totaal",
        delete: "Verwijderen",
        firstName: "Voornaam",
        lastName: "Achternaam",
        emailAddress: "E-mailadres",
        phonenumber: "Telefoonnummer",
        placeOrder: "Bestelling plaatsen",
        orderReceived: "Uw bestelling is in goede orde ontvangen.",
        proceedShopping: "U kunt meteen verder winkelen door op de onderstaande knop te klikken.",
        previous: "Vorige",
        action: "Actie",
        view: "Bekijken",
        branch: "Vestiging"
    },
    checkout: {
        shoppingCart: "winkelwagen"
    },
    navigation: {
        collection: "Collectie",
        orders: "Bestellingen"
    },
    messages: {
        error: {
            orderError: "Er is iets fout gegaan bij het plaatsen van uw bestelling.",
            addToCartError: "Er is iets fout gegaan. Het product kon niet worden toegevoegd aan de winkelwagen.",
            ordersFetch: "Er is iets fout gegaan bij het ophalen van de bestellingen"
        },
        warning: {
            discountInvalid: "Korting is gelijk aan of hoger dan het totaalbedrag van alle producten. Pas de korting aan.",
            maxAmountItems: (amount) => `Max. ${amount} stuks`,
            noStock: (name) => `${name} is op dit moment uitverkocht. Verwijder dit item uit de winkelwagen als u verder wilt gaan.`,
            oneInStock: (name) => `${name} heeft nog 1 stuk op voorraad. Wijzig het aantal van dit item als u verder wilt gaan.`,
            lessInStock: (stockAmount, name, chosenAmount) =>
                `${name} heeft nog ${stockAmount} stuks op voorraad. U koos er ${chosenAmount}. Wijzig het aantal als u verder wilt gaan.`,
            noStockForSize: (name, size) => `${name} in maat ${size} is uitverkocht. Verwijder dit item uit de winkelwagen als u verder wilt gaan.`,
            notEnoughStockForSize: (name, size, stock) =>
                `${name} in maat ${size} heeft niet meer genoeg voorraad. Nog ${stock} op voorraad. Pas het aantal aan van dit item en probeer het opnieuw.`,
            notEnoughStock: (name, stock) =>
                `Er is niet genoeg op voorraad van het product ${name} - nog ${stock} op voorraad - pas het aantal van dit product aan en probeer het nogmaals.`,
            noProductsInCartInStock: "Geen van de items in uw winkelwagen zijn op voorraad.",
            orderWasNotPlaced: "De bestelling is niet geplaatst.",
            sizeColorComboNotFound: "De geselecteerde kleur / maat combinatie kon niet worden gevonden.",
            noBranchMatch: "Let op: Uw account is niet gekoppeld aan een vestiging. Hierdoor kunt u geen bestellingen plaatsen.",
            branchFetchFailedNoMatch:
                "Let op: Er is iets fout gegaan bij het ophalen van de vestigingen. Hierdoor is er geen koppeling aan een vestiging gevonden en kunt u geen bestellingen plaatsen."
        },
        info: {
            noProductsFoundWithFilters: "Er zijn geen producten gevonden met de huidige filters.",
            noProductsInCart: "Er bevinden zich geen producten in uw winkelwagen.",
            amountUpdated: (name, changedAmount) => `Het aantal voor ${name} is aangepast naar ${changedAmount}.`,
            productWithColorAndSizeRemoved: (name, color, size) => `${name} met de kleur ${color} in maat ${size} is verwijderd uit uw winkelwagen.`,
            productWithSizeRemoved: (name, size) => `${name} in maat ${size} is verwijderd uit uw winkelwagen.`,
            noOrderHistory: "Er is geen order geschiedenis gevonden.",
            dataWillBeRefreshed: "De data van de applicatie is verouderd en wordt automatisch ververst. Een moment geduld alstublieft.",
            xOrdersFound: (count) => `${count} bestelling(en) gevonden`,
            orderIsBeingPlaced: "Uw bestelling wordt geplaatst."
        },
        success: {
            orderWasPlaced: "Uw bestelling is geplaatst.",
            hasBeenAddedToThe: "is toegevoegd aan de"
        },
        validation: {
            noBranchOrderDisallowed: "U kunt geen bestelling plaatsen, want u bent niet gekoppeld aan een vestiging.",
            noBranchOrderViewDisallowed: "U bent niet gekoppeld aan een vestiging,<br/>hierdoor kunt u <b>geen</b> orders inzien.",
            selectAColorPlease: "Selecteer een kleur alstublieft.",
            selectASizePlease: "Selecteer een maat alstublieft.",
            selectAnAmountPlease: "Selecteer een aantal alstublieft.",
            notEnoughInStock: "Er is niet genoeg op voorraad. Pas de aantallen aan.",
            notEnoughStock: "Er is niet genoeg op voorraad."
        },
        loading: {
            default: "Bezig met laden...",
            branches: "Vestigingen worden geladen...",
            products: "Producten worden geladen...",
            properties: "Eigenschappen worden geladen...",
            propertyTypes: "Eigenschappen types worden geladen...",
            categories: "Categorieën worden geladen...",
            inventory: "Voorraden worden geladen...",
            prices: "Prijzen worden geladen...",
            productProperties: "Producteigenschappen worden geladen...",
            stock: "Voorraad wordt geladen...",
            settings: "Instellingen worden geladen...",
            catalog: "Catalogus wordt samengesteld..."
        }
    }
};

export default nlNl;
