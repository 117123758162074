import { storage } from "@divide/ois-react-framework/common";
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";

export function inventoryBranchTypeUrl() {
    let webshopCode = storage.get(storageKeys.webshopCode);
    let url = `${appConfig.apis.ois.core.v1}Inventory/BranchType`;

    if (webshopCode) {
        url = `${appConfig.apis.ois.core.v1}Inventory/${webshopCode}/BranchType`;
    }
    
    return url;
}
