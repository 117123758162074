import { useEffect, useState } from "react";
import { storage } from "@divide/ois-react-framework/common";
import { useAuth0 } from "@divide/ois-react-components";
import storageKeys from "constants/storageKeys";

function useGetStoredCartAmount() {
    const [storedCartAmount, setStoredCartAmount] = useState(0);
    const { user, getTokenSilently } = useAuth0();

    useEffect(
        function () {
            async function init() {
                if (!user) return;

                let amount = 0;
                let cart = storage.get(storageKeys.cart);

                if (cart && cart.length > 0) {
                    cart = JSON.parse(cart);

                    cart.forEach((c) => {
                        amount += +c.amount;
                    });
                }

                setStoredCartAmount(Number(amount));
            }

            init();
        },
        [getTokenSilently, user]
    );

    return storedCartAmount;
}
export default useGetStoredCartAmount;
