import { useEffect } from 'react';
import { toast } from "react-toastify";

export default function useBranchWarning(userBranch, translation) {
    useEffect(() => {
        let status = userBranch?.status;
        let toastText;

        if (status === "HAS_NO_BRANCH") {
            toastText = (
                <p className="toast-content-encompass">{translation("messages.warning.noBranchMatch")}</p>
            );
        } else if (status === "ERROR") {
            toastText = (
                <p className="toast-content-encompass">{translation("messages.warning.branchFetchFailedNoMatch")}</p>
            );
        }

        if (toastText) {
            toast.warn(toastText, { 
                position: "top-left",
                autoClose: false, 
                draggable: false, 
                pauseOnFocusLoss: false
            });
        }

    }, [userBranch]);
}

