const frFr = {
    global: {
        appName: "Niet Handels Goederen",
        collection: "Le recueil",
        continueShopping: "Continuer vos achats",
        searchTermResults: (searchterm) => `Résultats de la recherche "${searchterm}"`,
        articlesAmount: (amount) => `${amount} article(s)`,
        color: "Couleur",
        colors: "Couleurs",
        size: "Taille",
        sizes: "Tailles",
        brand: "Marque",
        brands: "Marques",
        search: "Chercher",
        price: "Prix",
        noFiltersFound: (filterName) => `Aucun ${filterName} trouvé.`,
        moreFilters: (filterName) => `Plus de ${filterName}`,
        lessFilters: (filterName) => `Moins ${filterName}`,
        inStock: "En stock",
        selectedFilters: "Filtre(s) sélectionné(s)",
        deleteAllFilters: "Tout effacer",
        sortOptionName: "Nom",
        sortOptionPriceAsc: "Prix croissant",
        sortOptionPriceDesc: "prix décroissant",
        amount: "Nombre de",
        noProductFound: "Aucun produit trouvé",
        backToOverview: "Retour à l'aperçu",
        back: "Retour",
        gallery: "Galerie",
        stockInformation: "Informations boursières",
        noStockInfoFound: "Aucune information boursière n'a été trouvée.",
        noSizesFound: "Aucune taille n'a été trouvée.",
        noStoresFound: "Aucun magasin trouvé",
        noStockFound: "Aucun stock n'a été trouvé.",
        maxToOrder: "Max. à commander",
        moreBranches: "Plus de succursales",
        lessBranches: "Moins de succursales",
        addToCart: "Ajouter au panier",
        viewStockInformation: "Afficher les informations sur les actions",
        thankYou: "Merci",
        id: "ID",
        date: "Date",
        productNotInCatalog: "Le produit est hors gamme",
        information: "Informations",
        productInformation: "Information produit",
        articles: "Des articles",
        myOrders: "Mes commandes",
        shoppingCart: "panier",
        orderOverview: "Aperçu de la commande",
        orderNumber: "Numéro de commande",
        continueOrdering: "Continuer à commander",
        total: "Total",
        delete: "Retirer",
        firstName: "Prénom",
        lastName: "Nom de famille",
        emailAddress: "Adresse e-mail",
        phonenumber: "Numéro de téléphone",
        placeOrder: "Commander",
        orderReceived: "Votre commande a été reçue en bon état.",
        proceedShopping: "Vous pouvez immédiatement continuer vos achats en cliquant sur le bouton ci-dessous.",
        previous: "Précédent",
        action: "Action",
        view: "Voir",
        branch: "Bifurquer"
    },
    checkout: {
        shoppingCart: "panier"
    },
    navigation: {
        collection: "Le recueil",
        orders: "Ordres"
    },
    messages: {
        error: {
            orderError: "Une erreur s'est produite lors de la passation de votre commande.",
            addToCartError: "Une erreur s'est produite, le produit n'a pas pu être ajouté au panier.",
            ordersFetch: "Une erreur s'est produite lors de la récupération des commandes"
        },
        warning: {
            discountInvalid: "La remise est égale ou supérieure au montant total de tous les produits, ajustez la remise.",
            maxAmountItems: (amount) => `Max. ${amount} pièces`,
            noStock: (name) => `Il n'y a plus de stock pour le produit ${name}, retirez ce produit de votre panier.`,
            oneInStock: (name) => `Il reste 1 en stock du produit ${name}, veuillez modifier la quantité de ce produit si vous souhaitez continuer.`,
            lessInStock: (stockAmount, name, chosenAmount) =>
                `Il y a ${stockAmount} en stock du produit ${name}, vous avez choisi ${chosenAmount}, modifiez la quantité de ce produit si vous souhaitez continuer.`,
            noStockForSize: (name, size) => `Il n'y a plus de stock pour la taille ${size} du produit. ${name} supprimez ce produit de votre panier.`,
            notEnoughStockForSize: (name, size, stock) =>
                `Il n'y a pas assez de stock pour la taille ${size} du produit ${name} (encore ${stock} en stock), ajustez la quantité de ce produit et réessayez .`,
            notEnoughStock: (name, stock) =>
                `Il n'y a pas assez de stock pour le produit ${name} - ${stock} en stock - ajustez la quantité de ce produit et réessayez.`,
            noProductsInCartInStock: "Aucun des produits de votre panier n'est en stock.",
            orderWasNotPlaced: "La commande n'a pas été passée",
            sizeColorComboNotFound: "La combinaison couleur/taille sélectionnée est introuvable.",
            noBranchMatch: "Remarque: Vous n'êtes pas lié à un établissement, vous ne pouvez donc pas passer de commande.",
            branchFetchFailedNoMatch:
                "Remarque: une erreur s'est produite lors de la récupération des emplacements. Par conséquent, aucun lien vers un emplacement n'a été trouvé et vous ne pouvez pas passer de commande."
        },
        info: {
            noProductsFoundWithFilters: "Aucun produit n'a été trouvé avec les filtres actuels.",
            noProductsInCart: "Vous n'avez aucun produit dans votre panier.",
            amountUpdated: (name, changedAmount) => `Le montant de ${name} a été changé en ${changedAmount}.`,
            productWithColorAndSizeRemoved: (name, color, size) => `Le produit ${name} de couleur ${color} de taille ${size} a été supprimé de votre panier.`,
            productWithSizeRemoved: (name, size) => `Le produit ${name} de taille ${size} a été supprimé de votre panier.`,
            noOrderHistory: "Aucun historique de commande trouvé...",
            dataWillBeRefreshed: "Les données de l'application sont obsolètes et sont actualisées automatiquement, veuillez patienter.",
            xOrdersFound: (count) => `${count} commande(s) trouvée(s)`,
            orderIsBeingPlaced: "Votre commande est passée."
        },
        success: {
            orderWasPlaced: "Votre commande a été enregistrée.",
            hasBeenAddedToThe: "a été ajouté au"
        },
        validation: {
            noBranchOrderDisallowed: "Vous ne pouvez pas passer de commande car vous n'êtes pas lié à un établissement.",
            noBranchOrderViewDisallowed: "Vous n'êtes pas lié à un établissement,<br/>cela signifie que vous ne pouvez voir <b>aucune</b> commande.",
            selectAColorPlease: "Veuillez sélectionner une couleur.",
            selectASizePlease: "Veuillez choisir une taille.",
            selectAnAmountPlease: "Veuillez en sélectionner quelques-uns.",
            notEnoughInStock: "Il n'y a pas assez de stock, ajustez les quantités.",
            notEnoughStock: "Il n'y a pas assez de stock"
        },
        loading: {
            default: "Charger...",
            branches: "Chargement des lieux...",
            products: "Les produits sont en cours de chargement...",
            properties: "Chargement des propriétés...",
            propertyTypes: "Chargement des types de propriétés...",
            categories: "Chargement des catégories...",
            inventory: "Les stocks sont en cours de chargement...",
            prices: "Chargement des prix...",
            productProperties: "Chargement des propriétés du produit...",
            stock: "Les actions sont liées...",
            settings: "Chargement des paramètres...",
            catalog: "Chargement du catalogue..."
        }
    }
};

export default frFr;
