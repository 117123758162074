import { sharedStorageKeys } from "@divide/retailsuite-react-framework/constants";

const storageKeys = {
    version: "nhg_version",
    date: "nhg_date",
    cart: "nhg_cart",
    currentCategory: "nhg_current-category",
    currentFilters: "nhg_current-filters",
    currentSort: "nhg_current-sort",
    onStockCheck: "nhg_on-stock-check",
    onStockProductIds: "nhg_on-stock-product-ids",
    nextPage: "nhg_next-page",
    webshopCode: "nhg_webshop-code",
    cdnDomain: "nhg_cdn-domain",
    refreshTime: "nhg_refresh-time",
    addToHomeScreenMessageIsClosed: "nhg_add-to-home-screen-message-is-closed",
    hasUsedHomeScreenAppMessagesIsClosed: "nhg_has-used-home-screen-message-is-closed",
    hasUsedHomeScreenApp: "nhg_has-used-home-screen-app",
    updateAvailable: "nhg_update_available",
    ...sharedStorageKeys
};

export default storageKeys;