import { storage } from "@divide/ois-react-framework/common";
import storageKeys from "constants/storageKeys";
import appConfig from "constants/appConfig";

export function branchesUrl() {
    let webshopCode = storage.get(storageKeys.webshopCode);
    let url = `${appConfig.apis.ois.core.v1}Branches`;

    if (webshopCode) {
        url += `/${webshopCode}`;
    }

    return url;
}
