import { createContext, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { useTranslations, useResolveRoutesByAuthentication } from "@divide/ois-react-hooks";
import { useAuth0 } from "@divide/ois-react-components";
import { useCultureCode, useGetSettingsGroup, useGetConfigurationGroup, useGetUserHasAccess, useGetUserBranch, useRedirectionState, useIntervalReset } from "@divide/retailsuite-react-hooks";
import { Loader } from "@divide/retailsuite-react-components";
import { privateRoutes, publicRoutes } from "constants/routes"
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";
import translations from "constants/translations";
import useGetAllData from "hooks/general/useGetAllData";
import useSetCdnDomainToStorage from "hooks/general/useSetCdnDomainToStorage";
import useSetWebshopCodeToStorage from "hooks/general/useSetWebshopCodeToStorage";
import useGetStoredCartAmount from "hooks/useGetStoredCartAmount";
import useProductBarcodeScan from "hooks/useProductBarcodeScan";
import useSetShowOnStockProductsToStorage from "hooks/general/useSetShowOnStockProductsToStorage";
import useBranchWarning from "hooks/general/useBranchWarning";
import "./App.scss";

export const AppContext = createContext();

export function App() {
    const { cultureCode, setCultureCode } = useCultureCode(storageKeys.cultureCode);
    const { translation } = useTranslations(cultureCode, translations);
    const { loading, isAuthenticated } = useAuth0();
    const { routes, element } = useResolveRoutesByAuthentication(useAuth0, useRoutes, privateRoutes, publicRoutes);
    const webshopSettings = useGetSettingsGroup(useAuth0, appConfig, "Webshop");
    const generalConfiguration = useGetConfigurationGroup(useAuth0, appConfig, "General");
    const nhgConfiguration = useGetConfigurationGroup(useAuth0, appConfig, "NHG");
    const userBranch = useGetUserBranch(useAuth0, appConfig);

    useSetCdnDomainToStorage(webshopSettings.CdnDomain);
    useSetWebshopCodeToStorage(userBranch, nhgConfiguration.DefaultWebshopCodeNhg);
    useSetShowOnStockProductsToStorage(nhgConfiguration.ShowProductsWithoutStock);
    useBranchWarning(userBranch, translation);
    useIntervalReset(appConfig, storageKeys, 240);

    const { dataFetched, loadingText } = useGetAllData(translation, nhgConfiguration);
    const handleProductBarcodeScanResult = useProductBarcodeScan(translation, nhgConfiguration);
    const userAccess = useGetUserHasAccess(useAuth0, 4);
    const amount = useGetStoredCartAmount();
    const [catalogSearchTerm, setCatalogSearchTerm] = useState();
    const [catalogSearchActive, setCatalogSearchActive] = useState(false);
    const [cachedCatalog, setCachedCatalog] = useState();
    const [cartAmount, setCartAmount] = useState(0);
    
    useEffect(() => {
        if (amount) setCartAmount(amount);
    }, [amount]);
    
    const { redirection, setRedirection } = useRedirectionState();

    return loading ? (
        <Loader fullscreen={true} text={translation("messages.loading.default")} />
    ) : (
        <AppContext.Provider value={{ 
            cartAmount, 
            setCartAmount,
            cultureCode, 
            setCultureCode, 
            handleProductBarcodeScanResult,
            userAccess,
            userBranch,
            redirection,
            setRedirection,
            catalogSearchActive, 
            setCatalogSearchActive,
            catalogSearchTerm, 
            setCatalogSearchTerm,
            translation,
            cachedCatalog, 
            setCachedCatalog,            
            webshopSettings,
            generalConfiguration,
            nhgConfiguration,
            routes
        }}>
            {(!dataFetched && isAuthenticated) ? (
                <Loader fullscreen={true} text={loadingText} />  
            ) : (
                <>{element}</>
            )}
        </AppContext.Provider>
    );
}
 