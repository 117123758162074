import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Auth0Provider, useAuth0 } from "@divide/ois-react-components";
import { resolveOrganization } from "@divide/ois-react-framework/helpers";
import { StartUpProvider } from "@divide/retailsuite-react-components";

import auth0Redirection from "helpers/auth0Redirection";
import appConfig from "constants/appConfig";
import auth0Config from "constants/auth0Config";
import storageKeys from "constants/storageKeys";
import App from "parts/App";

import "react-photoswipe/lib/photoswipe.css";
import "react-toastify/dist/ReactToastify.css";

const container = document.querySelector("#root");
const root = createRoot(container);
const organizationId = resolveOrganization(storageKeys.organizationName);
const startUpProviderProps = { useAuth0, appConfig, storageKeys, toast };
const auth0ProviderProps = {
    domain: auth0Config.domain,
    audience: auth0Config.audience,
    client_id: auth0Config.clientId,
    scope: auth0Config.scopes,
    redirect_uri: window.location.origin,
    onRedirectCallback: auth0Redirection,
    organization: organizationId ? organizationId : undefined
}

root.render(
    <Auth0Provider {...auth0ProviderProps}>
        <StartUpProvider {...startUpProviderProps}>
            <BrowserRouter>
                <ToastContainer limit={5} />
                <App />
            </BrowserRouter>
        </StartUpProvider>
    </Auth0Provider>
);
