const enGb = {
    global: {
        appName: "Niet Handels Goederen",
        collection: "Collection",
        continueShopping: "Continue shopping",
        searchTermResults: (searchterm) => `Search results for "${searchterm}"`,
        articlesAmount: (amount) => `${amount} article(s)`,
        color: "Color",
        colors: "Colors",
        size: "Size",
        sizes: "Sizes",
        brand: "Brand",
        brands: "Brands",
        search: "Search",
        price: "Price",
        noFiltersFound: (filterName) => `No ${filterName} found.`,
        moreFilters: (filterName) => `More ${filterName}`,
        lessFilters: (filterName) => `Less ${filterName}`,
        inStock: "In stock",
        selectedFilters: "Selected filter(s)",
        deleteAllFilters: "Delete all",
        sortOptionName: "Name",
        sortOptionPriceAsc: "Price ascending",
        sortOptionPriceDesc: "Price descending",
        amount: "Amount",
        noProductFound: "Product not found",
        backToOverview: "Back to collection",
        back: "Back",
        gallery: "Gallery",
        stockInformation: "Stock information",
        noStockInfoFound: "No stock information was found.",
        noSizesFound: "No sizes were found.",
        noStoresFound: "No stores were found",
        noStockFound: "No stock was found.",
        maxToOrder: "Max. to order",
        moreBranches: "More branches",
        lessBranches: "Less branches",
        addToCart: "Add to cart",
        viewStockInformation: "View stock information",
        thankYou: "Thank you",
        id: "ID",
        date: "Date",
        productNotInCatalog: "The product is no longer available",
        information: "Information",
        productInformation: "Product information",
        articles: "Artikelen",
        myOrders: "My orders",
        shoppingCart: "shopping cart",
        orderOverview: "Order overview",
        orderNumber: "Order number",
        continueOrdering: "Continue ordering",
        total: "Total",
        delete: "Remove",
        firstName: "First name",
        lastName: "Last name",
        emailAddress: "E-mail address",
        phonenumber: "Phone number",
        placeOrder: "Place order",
        orderReceived: "Your order has been received in good order.",
        proceedShopping: "You can immediately continue shopping by clicking the button below.",
        previous: "Previous",
        action: "Action",
        view: "view",
        branch: "Branch"
    },
    checkout: {
        shoppingCart: "shopping cart"
    },
    navigation: {
        collection: "Collection",
        orders: "Orders"
    },
    messages: {
        error: {
            orderError: "Something went wrong while placing your order.",
            addToCartError: "Something went wrong. The product could not be added to the cart.",
            ordersFetch: "Something went wrong retrieving the orders."
        },
        warning: {
            discountInvalid: "Discount equals or exceeds the total amount of all products. Please adjust the discount.",
            maxAmountItems: (amount) => `Max. ${amount} pieces`,
            noStock: (name) => `${name} is out of stock. Please remove this item from your shopping cart.`,
            oneInStock: (name) => `${name} has only one item left in stock. Please change the quantity of this product if you want to continue.`,
            lessInStock: (stockAmount, name, chosenAmount) =>
                `${name} has only ${stockAmount} items in stock. You chose ${chosenAmount}. Please change the quantity of this product if you want to continue.`,
            noStockForSize: (name, size) => `${name} with size ${size} is out of stock. Please remove this product from the shopping cart.`,
            notEnoughStockForSize: (name, size, stock) =>
                `${name} with size ${size} has not enough stock available. Still ${stock} in stock. Please adjust the quantity of this product and try again .`,
            notEnoughStock: (name, stock) =>
                `There is not enough in stock of the product ${name} - ${stock} in stock - adjust the quantity of this product and try again.`,
            noProductsInCartInStock: "None of the items in your shopping cart are in stock.",
            orderWasNotPlaced: "Order has not been placed",
            sizeColorComboNotFound: "The selected color/size combination could not be found.",
            noBranchMatch: "Note: Your account is not linked to a store, therefor you cannot place orders.",
            branchFetchFailedNoMatch:
                "Note: Something went wrong while retrieving the locations. As a result, no link to a branch was found and you cannot place orders."
        },
        info: {
            noProductsFoundWithFilters: "No products were found with the current filters.",
            noProductsInCart: "You have no products in your shopping cart.",
            amountUpdated: (name, changedAmount) => `The amount for ${name} has been changed to ${changedAmount}.`,
            productWithColorAndSizeRemoved: (name, color, size) =>
                `The product ${name} with color ${color} in size ${size} has been removed from your shopping cart.`,
            productWithSizeRemoved: (name, size) => `The product ${name} in size ${size} has been removed from your shopping cart.`,
            noOrderHistory: "No order history found...",
            dataWillBeRefreshed: "The data of the application is outdated and is being refreshed automatically, please wait.",
            xOrdersFound: (count) => `${count} order(s) found`,
            orderIsBeingPlaced: "Your order is being placed."
        },
        success: {
            orderWasPlaced: "Your order has been placed.",
            hasBeenAddedToThe: "has been added to the"
        },
        validation: {
            noBranchOrderDisallowed: "You cannot place an order because you are not linked to a branch.",
            noBranchOrderViewDisallowed: "You are not linked to a branch,<br/>this means you <b>cannot</b> view orders.",
            selectAColorPlease: "Please select a color.",
            selectASizePlease: "Please select a size.",
            selectAnAmountPlease: "Please select an amount.",
            notEnoughInStock: "There is not enough in stock, adjust the quantities",
            notEnoughStock: "There is not enough in stock"
        },
        loading: {
            default: "Loading...",
            branches: "Loading branches...",
            products: "Loading products...",
            properties: "Loading properties...",
            propertyTypes: "Loading properties types...",
            categories: "Loading categories...",
            inventory: "Loading supplies...",
            prices: "Loading prices...",
            productProperties: "Loading product properties...",
            stock: "Loading stock...",
            settings: "Loading settings...",
            catalog: "Setting up catalog..."
        }
    }
};

export default enGb;
