import { lazy, Suspense } from "react";
import { sharedRoutes } from "./sharedRoutes";
import { Outlet } from "react-router-dom";

const ProductOverviewPage = lazy(() => import("pages/ProductOverviewPage"));
const ProductDetailPage = lazy(() => import("pages/ProductDetailPage"));
const OrdersPage = lazy(() => import("pages/OrdersPage"));
const OrderDetailPage = lazy(() => import("pages/OrderDetailPage"));
const Step1Page = lazy(() => import("pages/Step1Page"));
const Step2Page = lazy(() => import("pages/Step2Page"));
const Step3Page = lazy(() => import("pages/Step3Page"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));

export const privateRoutes = [
    {
        key: "root",
        element: <Outlet />,
        children: [
            {
                key: "productOverview",
                index: true,
                inMenu: true,
                matchEnd: true,
                path: "/",
                title: "navigation.collection",
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProductOverviewPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "productDetail",
                path: "/product",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProductDetailPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "orders",
                path: "/orders",
                title: "navigation.orders",
                inMenu: true,
                matchEnd: true,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <OrdersPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "orderDetail",
                path: "/orderdetail/:id",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <OrderDetailPage />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutCart",
                path: "/cart",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step1Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutCartStep1",
                path: "/cart/step1",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step1Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutCartStep2",
                path: "/cart/step2",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step2Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "checkoutCartStep3",
                path: "/cart/step3",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <Step3Page />
                    </Suspense>
                ),
                children: []
            },
            {
                key: "profile",
                path: "/profile",
                inMenu: false,
                scopes: [],
                roles: [],
                element: (
                    <Suspense fallback={<></>}>
                        <ProfilePage />
                    </Suspense>
                ),
                children: []
            },
            ...sharedRoutes
        ]
    },
];
