import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { hasProps } from "@divide/ois-react-framework/utils";
import useGetProductBarcodeScanResult from "hooks/useGetProductBarcodeScanResult";

function useProductBarcodeScan(translation, nhgConfiguration) {
    const navigate = useNavigate();

    let [productBarcodeScanResult, setProductBarcodeScanResult] = useState();
    let [productBarcodeScanResultTriggered, setProductBarcodeScanResultTriggered] = useState(false);

    let productBarcodeScanResponse = useGetProductBarcodeScanResult(productBarcodeScanResultTriggered, productBarcodeScanResult, translation, nhgConfiguration);

    const handleProductBarcodeScanResult = (result) => {
        setProductBarcodeScanResult(result);
        setProductBarcodeScanResultTriggered(true);
    };

    useEffect(() => {
        if (productBarcodeScanResultTriggered) setProductBarcodeScanResultTriggered(false);
    }, [productBarcodeScanResultTriggered]);

    useEffect(() => {
        if (productBarcodeScanResponse && hasProps(productBarcodeScanResponse)) {
            if (productBarcodeScanResponse.valid) {
                let productId = productBarcodeScanResponse.productId;
                let sizeId = productBarcodeScanResponse.sizeId;
                let colorId = productBarcodeScanResponse.colorId;

                navigate(`/product?productId=${productId}&sizeId=${sizeId}&colorId=${colorId}`);
            } else {
                toast.info(productBarcodeScanResponse.message);
            }
        }
    }, [productBarcodeScanResponse]);

    return handleProductBarcodeScanResult;

}

export default useProductBarcodeScan