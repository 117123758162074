import { storage, httpClient, successfulStatus } from "@divide/ois-react-framework/common";
import catalogStockWorker from "helpers/workers/catalogStockWorker";
import WebWorkerInstance from "helpers/webWorkerInstance";
import appConfig from "constants/appConfig";
import storageKeys from "constants/storageKeys";
import { catalogUrl } from "helpers/endpointUrls";

const webWorker = new WebWorkerInstance(catalogStockWorker);

async function getStockData(bearerToken, inventory, settings) {
    let response = await resolveStockData(catalogUrl(), bearerToken, inventory, settings);

    return response;
}

async function resolveStockData(api, bearerToken, inventory, settings) {
    let response = await httpClient.get(api, {}, bearerToken, true, appConfig);

    if (successfulStatus(response.status)) {
        await setProductIdsOnStock(response.content, inventory, settings);
    }

    return response;
}

async function setProductIdsOnStock(catalog, inventory, settings) {
    let minimumStock = 0;

    const catalogIdsOnStockObjects = new Map();
    
    catalog.forEach(item => {
        if (!catalogIdsOnStockObjects.has(item.Id)) {
            catalogIdsOnStockObjects.set(item.Id, inventory.filter(i => i.ProductId === item.Id));
        }
    });

    webWorker.postMessage({
        minimumStock,
        catalogIdsOnStockObjects
    });

    webWorker.addEventListener("message", (e) => {
        storage.set(storageKeys.onStockProductIds, JSON.stringify(e.data));
        webWorker.terminate();
    });

    return Promise.resolve();
}

export default getStockData;